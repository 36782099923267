import { onUnmounted, ref, watch } from '@vue/composition-api'
import { getIndex } from '@core/utils'
import store from '@/store'
import router from '@/router'
import itemStoreModule from '@/views/apps/rides/itemStoreModule'
import { i18n } from '@/plugins/i18n'

export default function controller() {
  const USER_APP_STORE_MODULE_NAME = 'app-ride'

  // Register module
  if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, itemStoreModule)

  // UnRegister on leave
  onUnmounted(() => {
    if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
  })
  const blankItem = {
    id: null,
    start_color: '#0C24DA',
    end_color: '#0C24DA',
    name: null,
    duration: null,
    total_rides: null,
    pick_location: null,
    drop_location: null,
    tests: [],
  }
  const form = ref()
  const items = ref([])
  const item = ref({})
  const signatureForm = ref({})
  const canEdit = (th, td) => {
    if (td && th.value && td.editable) {
      td.classes = td.classes || {}
      td.classes[th.value] = { 'w-100': true, 'h-100': true, 'add-padding': true }
    } else {

    }
  }
  const statuses = ref([
    { id: 0, label: 'Inactive' },
    { id: 1, label: 'Active' },
    { id: 2, label: 'Verified' },
    { id: -1, label: 'Suspended' },
  ])
  const businesses = ref([
    { id: 'restaurant', label: 'Restaurant' },
    { id: 'shop', label: 'Shop' },
  ])

  const tableColumns = [

    {
      text: 'Actions',
      value: 'actions',
      align: 'right',
      class: 'actions',
      width: '50px',
      sortable: false,
    },
    {
      align: 'center',
      text: 'Id',
      value: 'id',
      width: '100px',
      filter: { place_holder: '', type: 'number', value: router.currentRoute.query.id },
    },
    {
      text: 'Image',
      value: 'image',
      align: 'left',
      cellClass: 'text-left',
      editable: true,
      type: 'image',
      sortable: false,
      width: '150px',
      height: '100px',
      ratio: 16 / 16,
      cropHeight: 1400,
      crowWidth: 1400,
      info: 'Confirm the update of "Image" ?',
      fields: [
        {
          type: 'image',
          label: 'Image',
          value: 'image',
          required: false,
          width: '150px',
          height: '100px',
          ratio: 16 / 16,
          cropHeight: 1400,
          crowWidth: 1400,
        },
      ],
    },
    {
      text: 'Name',
      value: 'title',
      fixed: true,
      width: '150px',
      editable: true,
      required: true,
      type: 'text',
      info: 'Confirm the update of "Name" ?',
      requiredMessage: 'User name is required',
      sm: 12,
      md: 6,
      lg: 6,
      xl: 6,
      fields: [
        {
          type: 'text', label: 'Name', value: 'title', required: true,
        },
      ],
      filter: { place_holder: '', type: 'text' },
    },
    {
      text: 'Owner',
      value: 'user.name',
      object: 'user',
      type: 'select',
      fixed: true,
      required: true,
      info: 'Confirm the update of "Owner" ?',
      requiredMessage: 'User owner is required',
      width: '150px',
      selected_value: 'user_id',
      selected_label: 'name',
      itemLabel: 'name',
      itemValue: 'id',
      items: [],
      source: '/users',
      loading: false,
      search: '',
      editable: false,
      column_name: 'user_id',
      sm: 12,
      md: 6,
      lg: 6,
      xl: 6,
      fields: [
        {
          type: 'select',
          label: 'Owner',
          value: 'user_id',
          items: [],
          source: '/users',
          item_text: 'name',
          item_value: 'id',
        },
      ],
      filter: {
        place_holder: '',
        type: 'select',
        source: '/users',
        label: 'name',
        id: 'id',
        multiple: true,
        items: [],
        key: 'id',
      },
    },
    {
      text: 'Photos',
      value: 'photos',
      type: 'button',
      align: 'center',
      sortable: false,
    },

    {
      text: 'Specialties',
      value: 'specialties',
      object: 'specialty',
      with: 'specialties',
      type: 'select',
      sortable: false,
      translatable: true,
      info: 'Confirm the update of "Specialty" ?',
      requiredMessage: 'Specialty is required',
      width: '150px',
      multiple: true,
      selected_value: 'id',
      selected_label: 'name',
      itemLabel: 'name',
      itemValue: 'id',
      items: [],
      source: '/admin/specialties',
      editable: true,
      column_name: 'specialties_ids',
      sm: 12,
      md: 6,
      lg: 6,
      xl: 6,
      fields: [
        {
          type: 'select',
          label: 'Specialty',
          value: 'specialties_ids',
          items: [],
          source: '/admin/specialties',
          object: 'specialties',
          itemLabel: `name.${i18n.locale}`,
          itemValue: 'id',
          multiple: true,
          associated: 'specialties',
        },
      ],
      filter: {
        place_holder: '',
        type: 'select',
        source: '/admin/specialties',
        label: `name.${i18n.locale}`,
        id: 'id',
        multiple: true,
        items: [],
        key: 'shop_type.specialty_id',
        column: 'shop_specialty.specialty_id',
        join_table: 'shop_specialty',
        join_column: 'shop_specialty.shop_id',
        local_column: 'shops.id',
      },
    },

    {
      text: 'City',
      value: 'city.name',
      object: 'city',
      type: 'select',
      fixed: true,
      required: true,
      info: 'Confirm the update of "City" ?',
      requiredMessage: 'city city is required',
      width: '150px',
      selected_value: 'city_id',
      selected_label: 'name',
      itemLabel: 'name',
      itemValue: 'id',
      items: [],
      source: '/admin/cities',
      loading: false,
      search: '',
      editable: true,
      column_name: 'city_id',
      sm: 6,
      md: 6,
      lg: 6,
      xl: 6,
      fields: [
        {
          type: 'select',
          label: 'City',
          value: 'city_id',
          items: [],
          source: '/admin/cities',
          item_text: 'name',
          item_value: 'id',
        },
      ],
      filter: {
        place_holder: '',
        type: 'select',
        source: '/admin/cities',
        label: 'name',
        id: 'id',
        multiple: true,
        items: [],
        key: 'cities.id',
        column: 'shops.city_id',
        join_table: 'cities',
        join_column: 'shops.city_id',
        local_column: 'cities.id',
      },
    },
    {
      text: 'Zone',
      value: 'zone.name',
      object: 'zone',
      type: 'select',
      fixed: true,
      required: true,
      info: 'Confirm the update of "zone" ?',
      requiredMessage: 'Lab zone category is required',
      selected_value: 'zone_id',
      selected_label: 'name',
      itemLabel: 'name',
      itemValue: 'id',
      items: [],
      source: '/admin/zones',
      loading: false,
      translatable: true,
      search: '',
      editable: true,
      generate: true,
      column_name: 'zone_id',
      sm: 6,
      md: 6,
      lg: 6,
      xl: 6,
      fields: [
        {
          type: 'select',
          label: 'Zone',
          value: 'zone_id',
          items: [],
          source: '/admin/zones',
          item_text: `name.${i18n.locale}`,
          item_value: 'id',
        },
      ],
      filter: {
        place_holder: '',
        type: 'select',
        source: '/admin/zones',
        label: `name.${i18n.locale}`,
        id: 'id',
        multiple: true,
        items: [],
        key: 'zones.id',
        column: 'shops.zone_id',
        join_table: 'zones',
        join_column: 'shops.zone_id',
        local_column: 'zones.id',
      },
    },
    {
      text: 'Address',
      value: 'address',
      fixed: true,
      width: '150px',
      editable: true,
      required: true,
      type: 'text',
      info: 'Confirm the update of "Address" ?',
      requiredMessage: 'User address is required',
      sm: 12,
      md: 6,
      lg: 6,
      xl: 6,
      fields: [
        {
          type: 'text', label: 'Address', value: 'address', required: true,
        },
      ],
      filter: { place_holder: '', type: 'text' },
    },
    {
      text: 'Phone',
      value: 'phone',
      fixed: true,
      width: '150px',
      editable: true,
      required: true,
      type: 'text',
      info: 'Confirm the update of "Phone" ?',
      requiredMessage: 'User phone is required',
      sm: 12,
      md: 6,
      lg: 6,
      xl: 6,
      fields: [
        {
          type: 'text', label: 'Phone', value: 'phone', required: true,
        },
      ],
      filter: { place_holder: '', type: 'text' },
    },
    {
      text: 'Whatsapp',
      value: 'whatsapp',
      fixed: true,
      width: '150px',
      editable: true,
      type: 'text',
      info: 'Confirm the update of "Whatsapp" ?',
      requiredMessage: 'User whatsapp is required',
      sm: 12,
      md: 6,
      lg: 6,
      xl: 6,
      fields: [
        {
          type: 'text', label: 'Whatsapp', value: 'whatsapp', required: true,
        },
      ],
      filter: { place_holder: '', type: 'text' },
    },
    {
      text: 'Facebook',
      value: 'facebook',
      fixed: true,
      width: '150px',
      editable: true,
      type: 'text',
      info: 'Confirm the update of "Facebook" ?',
      requiredMessage: 'User facebook is required',
      sm: 12,
      md: 6,
      lg: 6,
      xl: 6,
      fields: [
        {
          type: 'text', label: 'Facebook', value: 'facebook', required: true,
        },
      ],
      filter: { place_holder: '', type: 'text' },
    },
    {
      text: 'Instagram',
      value: 'instagram',
      fixed: true,
      width: '150px',
      editable: true,
      type: 'text',
      info: 'Confirm the update of "Instagram" ?',
      requiredMessage: 'User instagram is required',
      sm: 12,
      md: 6,
      lg: 6,
      xl: 6,
      fields: [
        {
          type: 'text', label: 'Instagram', value: 'instagram', required: true,
        },
      ],
      filter: { place_holder: '', type: 'text' },
    },
    {
      text: 'Twitter',
      value: 'twitter',
      fixed: true,
      width: '150px',
      editable: true,
      type: 'text',
      info: 'Confirm the update of "Twitter" ?',
      requiredMessage: 'User twitter is required',
      sm: 12,
      md: 6,
      lg: 6,
      xl: 6,
      fields: [
        {
          type: 'text', label: 'Twitter', value: 'twitter', required: true,
        },
      ],
      filter: { place_holder: '', type: 'text' },
    },
    {
      text: 'Email',
      value: 'email',
      fixed: true,
      width: '150px',
      editable: true,
      type: 'text',
      info: 'Confirm the update of "Email" ?',
      requiredMessage: 'User email is required',
      sm: 12,
      md: 6,
      lg: 6,
      xl: 6,
      fields: [
        {
          type: 'text', label: 'Email', value: 'email', required: true,
        },
      ],
      filter: { place_holder: '', type: 'text' },
    },
    {
      text: 'Latitude',
      value: 'latitude',
      fixed: true,
      width: '150px',
      editable: true,
      type: 'number',
      info: 'Confirm the update of "Latitude" ?',
      requiredMessage: 'User latitude is required',
      sm: 12,
      md: 6,
      lg: 6,
      xl: 6,
      fields: [
        {
          type: 'number', label: 'Latitude', value: 'latitude', required: true,
        },
      ],
      filter: { place_holder: '', type: 'number' },
    },
    {
      text: 'Longitude',
      value: 'longitude',
      fixed: true,
      width: '150px',
      editable: true,
      type: 'number',
      info: 'Confirm the update of "Longitude" ?',
      requiredMessage: 'User longitude is required',
      sm: 12,
      md: 6,
      lg: 6,
      xl: 6,
      fields: [
        {
          type: 'number', label: 'Longitude', value: 'longitude', required: true,
        },
      ],
      filter: { place_holder: '', type: 'number' },
    },
    {
      text: 'Description',
      value: 'description',
      fixed: true,
      width: '200px',
      align: 'center',
      cellClass: 'text-center align-content-center justify-center',
      editable: true,
      type: 'textarea',
      rows: 3,
      info: 'Confirm the update of "Description" ?',
      fields: [
        {
          type: 'textarea', label: 'Description', value: 'description', required: false,
        },
      ],
      filter: { place_holder: '', type: 'text' },

      // filter: {
      //   place_holder: '',
      //   type: 'select',
      //   values: store.state.app.genres,
      //   label: 'label',
      //   id: 'id',
      //   multiple: true,
      //   column: 'rides.package_id',
      //   join_table: 'packages',
      //   join_column: 'packages.id',
      //   local_column: 'rides.genre_id',
      // },
    },
    {
      text: 'Status',
      value: 'status',
      type: 'select',
      align: 'center',
      width: '200px',
      selected_value: 'status',
      selected_label: 'label',
      itemLabel: 'label',
      itemValue: 'id',
      items: statuses.value,
      comment_column: 'status',
      editable: true,
      info: 'Confirm the update of "Status" ?',
      column_name: 'status',
      sm: 12,
      md: 6,
      lg: 6,
      xl: 6,
      fields: [
        {
          type: 'select',
          label: 'Status',
          value: 'status',
          items: statuses.value,
          item_text: 'label',
          item_value: 'id',
        },
      ],
      filter: {
        place_holder: '', type: 'select', column: 'status', values: statuses.value, label: 'label', id: 'id', multiple: false,
      },
    },
   /* {
      text: 'Type',
      value: 'type',
      type: 'select',
      align: 'center',
      width: '200px',
      selected_value: 'type',
      selected_label: 'label',
      itemLabel: 'label',
      itemValue: 'id',
      items: businesses.value,
      comment_column: 'type',
      editable: true,
      info: 'Confirm the update of "Type" ?',
      column_name: 'type',
      sm: 12,
      md: 6,
      lg: 6,
      xl: 6,
      fields: [
        {
          type: 'select',
          label: 'Type',
          value: 'type',
          items: businesses.value,
          item_text: 'label',
          item_value: 'id',
        },
      ],
      filter: {
        place_holder: '', type: 'select', column: 'type', values: businesses.value, label: 'label', id: 'id', multiple: false,
      },
    },*/

  ]

  const isSnackbarBottomVisible = ref(false)
  const snackBarMessage = ref('')
  const isFormActive = ref(false)
  const isPhotoFormActive = ref(false)
  const isDayFormActive = ref(false)
  const isMenuFormActive = ref(false)
  const isPropositionActive = ref(false)
  const isRelanceActive = ref(false)
  const deleteDialog = ref(false)
  const signatureDialog = ref(false)
  const accordDialog = ref(false)
  const downloadDialog = ref(false)
  const downloadObject = ref({ loading: false, url: '' })
  const searchQuery = ref('')
  const userFilter = ref(router.currentRoute.params.id)
  const payFilter = ref(null)
  const sourceFilter = ref(null)
  const statusFilter = ref(null)
  const stateFilter = ref(null)
  const totalItems = ref(0)
  const loading = ref(false)
  const filters = ref([])
  const options = ref({
    rowsPerPage: 10,
    itemsPerPage: 10,
    page: 1,
    sortDesc: [true],
  })
  const rideStates = ref({
    CREATED: 0,
    APPROVED: 1,
    SIGNED: 2,
    COLLECTED: 3,
  })
  const page = ref(1)
  const pageCount = ref(0)
  const selectedRows = ref([])
  const resetForm = () => {
    if (form.value) form.value.reset()
  }
  const select = data => {
    if (data.state) {
      selectedRows.value.push(data.item)
    } else {
      selectedRows.value.splice(selectedRows.value.findIndex(ele => ele.id === data.item.id), 1)
    }
  }

  /* filters.value = userFilter.value != null ? tableColumnsUserView : tableColumns */

  // new item
  const openDialog = val => {
    if (val && val.publication && val.publication.state >= store.state.app.states.RESILIATION_STATE) {
      return false
    }
    if (val) {
      item.value = val
    } else {
      item.value = JSON.parse(JSON.stringify(blankItem))
      resetForm()
    }
    isFormActive.value = !isFormActive.value
  }
  const openPhotoDialog = val => {
    item.value = val
    isPhotoFormActive.value = !isPhotoFormActive.value
  }
  const openDayDialog = val => {
    item.value = val
    isDayFormActive.value = !isDayFormActive.value
  }
  const openMenuDialog = val => {
    item.value = val
    isMenuFormActive.value = !isMenuFormActive.value
  }
  const openPropositionDialog = () => {
    isPropositionActive.value = !isPropositionActive.value
  }
  const openRelanceDialog = () => {
    isRelanceActive.value = !isRelanceActive.value
  }

  const openSignatureDialog = val => {
    if (val && val.publication && val.publication.state >= store.state.app.states.RESILIATION_STATE) {
      return false
    }
    item.value = val
    signatureDialog.value = !signatureDialog.value
  }

  const openAccordDialog = val => {
    if (val && val.publication && val.publication.state >= store.state.app.states.RESILIATION_STATE) {
      return false
    }
    item.value = val
    accordDialog.value = !accordDialog.value
  }

  // fetch data
  const fetchItems = download => {
    accordDialog.value = false
    signatureDialog.value = false
    isFormActive.value = false
    isPhotoFormActive.value = false
    isPropositionActive.value = false
    isRelanceActive.value = false
    downloadObject.value = { loading: true, url: null }

    const singleFilters = filters.value.filter(ele => ele.filter != null && ele.filter.value !== '' && ele.filter.value != null && (ele.filter.value.constructor !== Array || ele.filter.value.length > 0)).map(ele => ({
      ...ele.filter,
      key: ele.key || ele.value,
      values: null,
    }))

    // const multipleFilters = filters.value.filter(ele => ele.filters != null && ele.filters.length > 0).map(th => th.filters.map(ele => ({ ...ele.filter, key: ele.value, values: null })))
    const multipleFilters = filters.value.filter(ele => ele.filters != null && ele.filters.length > 0)
    const selectedMultipleFilters = []
    for (let i = 0; i < multipleFilters.length; i++) {
      selectedMultipleFilters.push(...multipleFilters[i].filters
        .filter(ele => ele.value !== '' && ele.value != null && (ele.value.constructor !== Array || ele.value.length > 0))
        .map(ele => ({ ...ele, values: null/* , type: null */ })))
    }

    store
      .dispatch('app-shop/fetchItems', {
        /* filters: filters.value.filter(ele => ele.filter != null && ele.filter.value !== '' && ele.filter.value != null && (ele.filter.value.constructor !== Array || ele.filter.value.length > 0))
          .map(ele => ({ ...ele.filter, key: ele.value, values: null })), */
        filters: [...singleFilters, ...selectedMultipleFilters],
        term: searchQuery.value,
        state: stateFilter.value,
        status: statusFilter.value,
        pay: payFilter.value,
        user: userFilter.value,
        download,
        source: sourceFilter.value,
        orderBy: options.value.sortBy ? options.value.sortBy[0] : 'id',
        // eslint-disable-next-line no-nested-ternary
        direction: options.value.sortDesc ? (options.value.sortDesc[0] ? 'DESC' : 'ASC') : 'DESC',
        page: options.value.page,
        per_page: options.value.itemsPerPage,
      })
      .then(response => {
        if (download) {
          downloadObject.value = {
            loading: false,
            url: response.data || null,
          }
        } else {
          const { data, total } = response.data
          items.value = data.map(ele => {
            ele.photos = ele.photos || []
            ele.days = ele.days || []
            ele.types = ele.types || []
            ele.types_ids = ele.types.map(el => el.id)

            return ele
          })
          totalItems.value = total
        }

        // remove loading state
        loading.value = false
      })
      .catch(error => {
        console.log(error)
      })
      .then(() => {
        loading.value = false
      })
  }
  const confirmDelete = val => {
    item.value = val
    deleteDialog.value = true
  }
  const deleteItem = () => {
    if (item.value) {
      loading.value = true
      store.dispatch('app-shop/deleteItem', item.value.id)
        .then(response => {
          loading.value = false
          deleteDialog.value = false
          fetchItems()
          // eslint-disable-next-line no-unused-vars
        }).catch(error => {
          loading.value = false
        })
    }
  }
  // eslint-disable-next-line no-unused-vars
  let timeout = null
  watch([filters], () => {
    if (timeout) {
      clearTimeout(timeout)
    }
    timeout = setTimeout(() => {
      loading.value = true
      selectedRows.value = []
      fetchItems()
    }, 500)
  }, { deep: true })
  watch([searchQuery, payFilter, sourceFilter, statusFilter, options], () => {
    loading.value = true
    selectedRows.value = []
    fetchItems()
  }, { deep: true })

  const saved = data => {
    fetchItems()
    item.value = JSON.parse(JSON.stringify(blankItem))
  }

  return {
    saved,
    rideStates,
    item,
    signatureForm,
    items,
    downloadDialog,
    downloadObject,
    filters,
    tableColumns,
    searchQuery,
    payFilter,
    sourceFilter,
    statusFilter,
    stateFilter,
    totalItems,
    loading,
    options,
    selectedRows,
    select,
    isFormActive,
    isPhotoFormActive,
    isDayFormActive,
    isMenuFormActive,
    isPropositionActive,
    blankItem,
    form,
    page,
    pageCount,
    snackBarMessage,
    isSnackbarBottomVisible,
    signatureDialog,
    accordDialog,
    isRelanceActive,
    openRelanceDialog,
    openSignatureDialog,
    openAccordDialog,
    resetForm,
    openDialog,
    openPhotoDialog,
    openDayDialog,
    openMenuDialog,
    openPropositionDialog,
    deleteItem,
    deleteDialog,
    confirmDelete,
    fetchItems,
    statuses,
  }
}
